<lib-collapse-panel
  [visible]="hasHierarchy()"
  [handleType]="hasHierarchy() ? 'icon' : 'none'"
  [initialWidth]="320"
  [minWidth]="320">
  <div class="flex h-full w-full flex-col gap-1 bg-surface-0 px-4 pt-5 dark:bg-surface-800">
    <div class="flex gap-1">
      <div
        class="hierarchy-tab-button"
        [ngClass]="{ active: selectedTab() === 'full' }"
        (click)="selectedTab.set('full')">
        <svg-icon class="min-h-5 min-w-5" size="sm" key="list"></svg-icon>
        <p class="text-sm">{{ 'Hierarchy' | translate }}</p>
      </div>

      <div
        class="hierarchy-tab-button"
        [ngClass]="{ active: selectedTab() === 'favorites' }"
        (click)="selectedTab.set('favorites')">
        <svg-icon class="min-h-5 min-w-5" size="sm" key="star"></svg-icon>
        <p class="text-sm">{{ 'Favorites' | translate }}</p>
      </div>
    </div>

    <p-divider></p-divider>

    @switch (selectedTab()) {
      @case ('full') {
        <lib-full-hierarchy
          [hierarchy]="hierarchy()"
          [favorites]="favorites()"
          [selectedNode]="selectedNode()"
          [energyBlocks]="energyBlocks()"
          [showConfigMenu]="showConfigMenu()"
          (selectNode)="selectNode($event)"
          (toggleFavorite)="toggleFavorite($event)"></lib-full-hierarchy>
      }
      @case ('favorites') {
        @if (favorites().size === 0) {
          <div class="flex h-full flex-col items-center justify-center text-surface-400">
            <svg-icon class="text-surface-400" size="xxl" key="star"></svg-icon>
            <p class="text-center text-lg">{{ 'No favorites.' | translate }}</p>
            <p (click)="selectedTab.set('full')" class="cursor-pointer text-center text-sm hover:underline">
              {{ 'Add favorites from the hierarchy.' | translate }}
            </p>
          </div>
        } @else {
          <lib-favorite-hierarchy
            [hierarchy]="hierarchy()"
            [favorites]="favorites()"
            [selectedNode]="selectedNode()"
            [energyBlocks]="energyBlocks()"
            [showConfigMenu]="showConfigMenu()"
            (selectNode)="selectNode($event)"
            (toggleFavorite)="toggleFavorite($event)"></lib-favorite-hierarchy>
        }
      }
    }
  </div>
</lib-collapse-panel>
