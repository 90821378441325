import { CommonModule } from '@angular/common';
import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';
import { ApplicationStorageService } from '@logic-suite/shared/storage';
import { SvgIconComponent, SvgIcons } from '@ngneat/svg-icon';
import { TranslateModule } from '@ngx-translate/core';
import { RouterService } from '@suite/router';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { TooltipModule } from 'primeng/tooltip';

// TODO: Cross app imports like this should be avoided. This creates a lot of noise in the nx graph.
import { AccessService, CustomerService } from '../../../../../../apps/suite/src/app/shared/access';

interface NavLink {
  path: string;
  tooltip: string;
  icon: SvgIcons;
}

@Component({
  selector: 'lib-rail',
  imports: [
    CommonModule,
    DividerModule,
    ButtonModule,
    AvatarModule,
    TooltipModule,
    RouterLink,
    SvgIconComponent,
    TranslateModule,
  ],
  templateUrl: './rail.component.html',
  styleUrl: './rail.component.scss',
})
export class RailComponent implements OnInit {
  private readonly accessService = inject(AccessService);
  private readonly applicationStorageService = inject(ApplicationStorageService);
  private readonly customerService = inject(CustomerService);
  private readonly routerService = inject(RouterService);

  currentUser = computed(() => this.accessService.currentUser());
  currentCustomer = toSignal(this.customerService.selectedCustomer$);
  canSelectCustomer = signal(this.applicationStorageService.getItem('user.hasMultipleCustomers') ?? false);
  selectCustomerTooltip = computed(() => {
    const currentCustomer = this.currentCustomer();
    if (!currentCustomer) {
      return 'Unknown customer';
    }

    return `${currentCustomer.customerID} - ${currentCustomer.name}`;
  });

  primaryNavLinks = signal<NavLink[]>([]);

  secondaryNavLinks = signal<NavLink[]>([]);

  ngOnInit(): void {
    // Build primary nav links based on Application `hasAccess` flag
    this.accessService.getApplicationList().subscribe((apps) => {
      this.primaryNavLinks.set([
        ...apps
          .filter((a) => a.hasAccess)
          .map((app) => ({
            path: app.applicationUrl,
            tooltip: app.applicationName,
            icon: this.getIcon(app.applicationID) as SvgIcons,
          })),
        {
          icon: 'clipboard',
          path: '/asset-log',
          tooltip: 'Asset log',
        },
      ]);
    });

    // Build secondary nav links based on Feature access
    this.accessService.isInitialized$.subscribe(() => {
      const links = [
        ...(this.accessService.hasFeature('configuration', 'R')
          ? [{ path: '/config', tooltip: 'Configuration', icon: 'cog' as SvgIcons }]
          : []),
        ...(this.accessService.hasFeature('acl-config', 'R')
          ? [
              {
                path: '/acl',
                tooltip: 'ACL',
                icon: 'acl' as SvgIcons,
              },
            ]
          : []),
        ...(this.accessService.hasFeature('user-management', 'R')
          ? [{ path: '/users', tooltip: 'User management', icon: 'users' as SvgIcons }]
          : []),
      ];
      this.secondaryNavLinks.set(links);
    });

    // Hide or display select customer link based on users customer list length
    this.accessService.getCustomerList().subscribe((customers) => {
      this.applicationStorageService.setItem('user.hasMultipleCustomers', customers.length > 1);
      this.canSelectCustomer.set(customers.length > 1);
    });
  }

  isActive(path: string): boolean {
    return this.routerService.currentUrl().startsWith(path.slice(0, -1)) ?? false;
  }

  private getIcon(applicationID: number) {
    // prettier-ignore
    switch (applicationID) {
      case 1:
        return 'energy';
      case 2:
        return 'bell';
      case 3:
        return 'chip';
      case 4:
        return 'flow';
      case 5:
        return 'money';
    }
    return '';
  }

  goBack() {
    window.history.back();
  }

  // TODO(bjhandeland): Move this code to the User class when it is available.
  avatarInitials = computed(() => {
    const user = this.currentUser();
    if (!user) {
      return '';
    }

    // Try to get initials from first and last name
    const firstName = user.firstName?.trim();
    const lastName = user.lastName?.trim();

    if (firstName && lastName) {
      return `${firstName[0]}${lastName[0]}`.toUpperCase();
    }

    // If we have just firstName or just lastName, use up to 2 chars
    if (firstName) {
      return firstName.slice(0, 2).toUpperCase();
    }

    if (lastName) {
      return lastName.slice(0, 2).toUpperCase();
    }

    // Fallback to email
    const email = user.email.trim();

    // Try to get username part before @ symbol
    const username = email.split('@')[0];

    // If username exists, take first two chars
    if (username) {
      return username.slice(0, 2).toUpperCase();
    }

    // Ultimate fallback - first two chars of email
    return email.slice(0, 2).toUpperCase();
  });
}
